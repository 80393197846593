<template>
	<div class="expain-content">
		<header class="expain-head">打“分儿”定级与酬金说明文档</header>
    <div class="title">
      1.“分儿”的评委分为四个等级：A、B、C、D
    </div>
    <div class="word">a.这个等级是根据每天打分的标准程度计算的，每天会进行一次刷新</div>
    <div class="word">b.A是最高等级，B次之，以此类推</div>
    <div class="word">c.当评“分儿”十分准确且活跃时，有机会触发“星级”</div>
    <div class="title">
      2.酬金说明
    </div>
    <div class="word">a.各等级对应税前单次酬金</div>
    <table border="1px" cellspacing="0" width="315px">
    <tr><th>等级</th><th>每次打分酬金（元）税前</th></tr>
    <tr><td>D</td><td>0</td></tr>
    <tr><td>C</td><td>0.5</td></tr>
    <tr><td>B</td><td>1</td></tr>
    <tr><td>A</td><td>1.3</td></tr>
    </table>
    <div class="explain">（此处仅展示高优先级任务的报酬，低优先级为高优先级报酬的一半）</div>
  <div class="word">b.当成为星级打分人时，有机会触发惊喜红包</div>
  <div class="text">感谢支持任务叮咚，请继续保持活跃地打分(＾Ｕ＾)ノ~ＹＯ</div>
	</div>
</template>

<script>
export default {
  metaInfo: {
      meta: [{
              charset: 'utf-8'
          },
          {
              name: 'viewport',
              content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
          }
       ]
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
 @import '../../common/style/explain.scss';
</style>